import React from "react";
import Title from "../layouts/Title";
import {
  projectOne,
  projectTwo,
  projectThree,
  projectFour,
  projectFive,
  projectSix,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title des={t("projects")} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title={"Pledge for the Planet"}
          des={t("pledgeDesc")}
          src={projectSix}
          websiteLink="https://www.pledgefortheplanet.org/"
        />
        <ProjectsCard
          title={"DLP Manager"}
          des={t("dlpManagerDesc")}
          src={projectFive}
          websiteLink="https://dlpmanager.com/"
        />
        <ProjectsCard
          title="Axees"
          des={t("axeesDesc")}
          src={projectFour}
          websiteLink="https://axees.io/"
        />
        <ProjectsCard
          title="Mobile Gear"
          des={t("mobilegearDesc")}
          src={projectOne}
          githubLink="https://github.com/alba-97/mobile_gear_front"
          websiteLink="https://mobilegear.netlify.app/"
        />
        <ProjectsCard
          title="The Event Network"
          des={t("clubdelplanDesc")}
          src={projectTwo}
          githubLink="https://github.com/alba-97/ceibo-web"
          websiteLink="https://eventnetwork.netlify.app"
        />
        <ProjectsCard
          title={t("tmdbTitle")}
          des={t("tmdbDesc")}
          githubLink="https://github.com/alba-97/tmdb-solo-week"
          src={projectThree}
        />
      </div>
    </section>
  );
};

export default Projects;
